<template>
  <div class="terms">
    <div class="container">
      <img
            class="container__logo"
            :src="
              'https://r2service-production.s3-sa-east-1.amazonaws.com/assets/images/logo-ruadois.png'
            "
      >
        <h2>Termos de Serviço</h2>

        <p>Este instrumento contém os termos gerais e condições de uso dos serviços de plataforma de software disponibilizados pela <strong>RUA DOIS SERVIÇOS DE TECNOLOGIA DA INFORMACAO LTDA</strong>, pessoa jurídica de direito privado, com sede na Rua Sader Macul, 96, Itaim Bibi, São Paulo/SP, CEP 04.542-090, inscrita no Cadastro Nacional de Pessoas Jurídicas (“CNPJ/MF”) sob o nº 32.072.669/0001-85 (<strong>RuaDois</strong>).</p>

        <p>PREÂMBULO</p>

        <p>CONSIDERANDO QUE A ACEITAÇÃO DESTES TERMOS DE USO É ABSOLUTAMENTE INDISPENSÁVEL À UTILIZAÇÃO DA PLATAFORMA E DOS SERVIÇOS OFERECIDOS PELA RUADOIS.</p>
        <p>- Considerando que a RuaDois desenvolveu e opera uma plataforma de software voltada para a gestão de processos comerciais e administrativos ligados ao segmento de locação do mercado imobiliário (<strong>Plataforma</strong>);</p>
        <p>- Considerando que estes Termos de Uso regem os termos e condições de utilização desta Plataforma, contemplando, igualmente, qualquer informação, texto, imagem e/ou material que sejam inseridos (upload), baixados (download) ou que estejam, de alguma forma, ligados aos Serviços oferecidos pela RuaDois, dispostos abaixo, na cláusula 1 (<strong>Termos de Uso</strong>);</p>
        <p>- Considerando que a Política de Privacidade é parte intrínseca e indissociável deste Termo de Uso, a aceitação das condições aqui presentes implica, necessariamente, na aceitação da Política de Privacidade, cujo teor pode ser acessado em http://www.ruadois.com.br/politicasprivacidade. A saber, A Política de Privacidade trata de diretrizes e princípios referentes à coleta, uso, armazenamento, tratamento, segurança e controle das informações pessoais dos usuários da Plataforma.</p>
        <p>- Considerando que o acesso e o uso da Plataforma são direcionados exclusivamente (i) aos usuários maiores de 18 (dezoito) anos, residentes e domiciliados no Brasil, que possuam plena capacidade legal para a sua utilização; e (ii) às sociedades em situações regular, legalmente constituídas e existentes de acordo com a legislação aplicável (<strong>Usuários</strong>). (Caso a <strong>RuaDois</strong> detecte alguma conta feita a partir de informações falsas, inconsistentes ou por empresas irregulares ou inexistentes, essa conta será automaticamente deletada e estes Termos de Uso serão automaticamente rescindidos, independentemente de qualquer aviso ou notificação).</p>

        <h3>1. Serviços</h3>

        <p>1.1. Os Serviços são oferecidos por meio da Plataforma, a qual é encontrada nos meios eletrônicos da <strong>RuaDois</strong>. As funcionalidades e ferramentas disponíveis ao Usuário variam de acordo com o plano escolhido durante a negociação comercial. Outrossim, sujeitam-se, também, aos módulos disponibilizados pela <strong>RuaDois</strong>, tendo em vista que esses variam conforme o momento do negócio da empresa ou o nível de integração do Usuário, podendo envolver, mas não se restringindo a:</p>
        <ul style="list-style-type: circle;">
          <li>Sistema para gerenciamento de visitas ou serviço de demonstração de imóveis (que pode ser vinculado a um Widget para agendamento de visitas colocado na página do Usuário ou em seus portais);</li>
          <li>Fechamento comercial de proposta de locação – sistema de negociação, análise de crédito e contrato digital;</li>
          <li>Integração com portais externos;</li>
          <li>Página web/mobile para cadastro de imóveis vinculado ou não a um serviço de fotografias profissionais de imóveis para divulgação online;</li>
          <li>Sistema do programa indicação/afiliação;</li>
          <li>Calculadora de preço de locação;</li>
          <li>Dashboard com métricas da operação de locação residencial (fornecido conforme critério da <strong>RuaDois</strong>);</li>
          <li>Melhores esforços para Integração com CRMs e sistema do usuário (não inclusos eventual customização);</li>
          <li>Portal web/mobile White Label;</li>
          <li>Insights de crescimento.</li>
        </ul>
        <p>1.2. Algumas funcionalidades da Plataforma também são acessíveis por meio de dispositivos móveis, como tablets e celulares, através de um aplicativo disponível para download (<strong>Aplicativo</strong>), cujo uso pode ser regido por termos e condições adicionais. Neste caso, os termos e condições adicionais do Aplicativo são incorporados a estes Termos de Uso por referência e, por conseguinte, também vinculam os Usuários. O uso dos Serviços por meio do Aplicativo pode implicar na exibição de ícones e dados no dispositivo móvel, cujo conteúdo pode ser atualizado periodicamente pela Internet e/ou sistema de comunicações. O Usuário é responsável por todo e qualquer encargo cobrado pela operadora de telefonia móvel para estabelecer conexão do Aplicativo na Internet e viabilizar o uso do Serviço pelo Usuário. Baixar, instalar ou utilizar determinados aplicativos pode ser proibido ou restrito pela operadora de telefonia móvel do Usuário ou pode ser tecnicamente limitado pelo próprio dispositivo móvel, de forma que as funcionalidades da Plataforma inseridas no Aplicativo podem não funcionar em alguns dispositivos móveis ou operadoras de telefonia celular.</p>

        <h3>2. Cadastro</h3>

        <p>2.1. Para a contratação dos Serviços é necessário que o Usuário realize um cadastro prévio, para o qual precisará fornecer voluntariamente informações sobre si, tais como: nome da empresa da qual faz parte, cadastro nacional da pessoa jurídica (CNPJ), telefone fixo, celular, data de nascimento, endereço e outras informações necessárias (<strong>Informações Pessoais</strong>). O Usuário declara que as Informações Pessoais fornecidas são fiéis e verdadeiras e compromete-se a manter seus dados sempre atualizados. A <strong>RuaDois</strong> não é responsável pelas informações prestadas, mas se reserva o direito de verificar, a qualquer momento, a veracidade de tais informações e solicitar, a seu exclusivo critério, documentação suplementar que julgue necessária para a devida comprovação das informações prestadas. Caso a <strong>RuaDois</strong> detecte alguma conta feita a partir de informações falsas, o cadastro do Usuário será automaticamente cancelado, de forma que o Usuário não mais terá acesso ao uso da Plataforma e a qualquer Serviço (independentemente de qualquer aviso ou notificação), não assistindo ao Usuário, por esse motivo, qualquer sorte de indenização ou ressarcimento.</p>
        <p>2.2. O titular e administrador da conta será aquele definido no momento do cadastro, a partir dos dados oferecidos pelo representante do Usuário (<strong>Usuário Administrador</strong>). A conta é pessoal e poderá ser acessada mediante a utilização de login e senha criados pelo próprio Usuário no momento do cadastro, sendo ele o único e exclusivo responsável por manter o sigilo de seu login e senha, a fim de garantir a segurança de sua conta e impedir o acesso não autorizado por terceiros. O Usuário é o único responsável por todas as atividades associadas à sua conta. Em caso de perda, extravio ou suspeita de utilização indevida de sua conta, login ou senha, a <strong>RuaDois</strong> deverá ser imediatamente comunicada para que sejam tomadas as medidas cabíveis. Caso seja verificada a duplicidade de contas, a <strong>RuaDois</strong> poderá inabilitar de forma definitiva todos os cadastros duplicados, independentemente de qualquer notificação prévia.</p>

        <h3>3. Planos de Assinatura</h3>

        <p>3.1. Os Serviços poderão ser contratados mediante a assinatura de um plano pelo Usuário (<strong>Assinatura</strong>), conforme descritos nas propostas enviadas e/ou site da RuaDois.Em todos os planos, os Serviços serão descritos no Quadro Resumo devidamente assinado digitalmente pelas Partes, no qual constará, entre outras informações, a descrição dos Serviços que serão prestados pela <strong>RuaDois</strong> e as suas especificidades, as obrigações da <strong>RuaDois</strong> e do Usuário, o prazo de vigência, os valores e as formas de pagamento, a multa por descumprimento ou quebra de contrato, os termos de responsabilidade civil, de propriedade intelectual, de sigilo, e qualquer outra informação que as partes estipularem consensualmente. Valores adicionais poderão ser devidos.</p>
        <p>3.2. Na contratação de qualquer um dos Planos, será necessário a realização de um processo de implementação dos produtos para que o Usuário (empresa contratante) passe a utilizar a Plataforma e os Serviços da RuaDois. Para realização da implementação dos produtos digitais em questão (<strong>Processo de Transformação Digital</strong>) poderão ser cobrados os valores do custo de implementação (<strong>Setup Fee</strong>) eventualmente apresentados nas propostas e/ou site da <strong>RuaDois</strong>.</p>
        <p>3.3. O Usuário Administrador é o único responsável por permitir o acesso dos demais usuários da empresa à Plataforma, bem como é o único que possui controle sobre os acessos dos demais usuários. Caso os demais usuários desejem obter qualquer informação sobre a conta, deverão entrar em contato exclusivamente com o Usuário Administrador. A desativação de qualquer dos demais usuários, por qualquer motivo, também é de exclusiva responsabilidade do Usuário Administrador. A <strong>RuaDois</strong> não possui qualquer ingerência no que tange ao acesso ou a desativação dos Usuários Cadastrados.</p>

        <h3>4. Cobrança do Serviço</h3>

        <p>4.1. A <strong>RuaDois</strong> oferece opção de pagamento via boleto bancário e cartões de crédito. As transações são realizadas por meio de plataforma intermediadora de pagamento escolhida pela <strong>RuaDois</strong>.</p>
        <p>4.2. Ocasionalmente, poderemos alterar nossos planos e os preços de nossos serviços, os quais serão atualizados e disponibilizados online. No entanto, todas as alterações efetuadas nos preços ou planos do serviço somente serão aplicadas 30 dias após a notificação enviada ao Usuário. Os preços da assinatura poderão ser ajustados automaticamente a cada ano ou com maior frequência, conforme permitido pela legislação vigente e segundo o Índice Geral de Preços do Mercado (IGP-M) publicado pela Fundação Getúlio Vargas ou outro índice equivalente.</p>

        <h3>5. Cancelamento</h3>

        <p>5.1. Salvo escrito como observação no Quadro Resumo, a assinatura de qualquer plano é feita pelo prazo de 12 (doze) meses. Findo esse prazo, a assinatura será automaticamente renovada. Caso o Usuário deseje cancelar a assinatura, ele deverá informar à RuaDois com, no mínimo, 15 (quinze) dias de antecedência. Caso contrário, os valores daquela mensalidade serão considerados vigentes e continuarão a ser cobrados do usuário.</p>
        <p>5.2 Em caso de cancelamento de qualquer Plano antes do término da vigência, o usuário estará sujeito a uma multa de 50% dos valores devidos até a data prevista para o término do contrato.</p>
        <p>5.3. O Usuário está ciente de que ao cancelar a Assinatura e/ou desfazer seu cadastro, a ação é irreversível e todas as suas Informações Pessoais, bem como todo o conteúdo inserido na Plataforma serão apagados, não sendo possível recuperar qualquer informação perdida. A <strong>RuaDois</strong>, em hipótese alguma, poderá ser responsabilizada por qualquer dano proveniente do cancelamento da Assinatura e/ou desativação do cadastro.</p>
        <p>5.4. A <strong>RuaDois</strong> reserva-se ao direito de suspender ou cancelar, a qualquer momento, o acesso de qualquer Usuário à Plataforma em caso de comprovada fraude, obtenção de benefício ou vantagem de forma ilícita ou pelo não cumprimento de quaisquer das condições destes Termos de Uso. Nesses casos, não será devida qualquer indenização ao Usuário, podendo a <strong>RuaDois</strong> promover a competente ação de regresso, se necessário, bem como quaisquer outras medidas necessárias para perseguir e resguardar seus interesses.</p>

        <h3>6. Responsabilidades da RuaDois</h3>

        <p>6.1. A <strong>RuaDois</strong> garante que, ressalvadas as hipóteses previstas nestes Termos de Uso, a Plataforma funcionará 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, inclusive feriados, devendo apresentar plenas condições de acesso e funcionamento durante, no mínimo, 90% (noventa por cento) do tempo. Em caso de constatação de falhas na utilização da Plataforma ou dificuldades de acesso, a central de suporte da <strong>RuaDois</strong> deverá ser comunicada, pelo e-mail suporte@ruadois.com.br. Manutenções preventivas ou corretivas poderão ser necessárias e as condições de acesso e funcionamento poderão sofrer alterações.</p>
        <p>6.2. A <strong>RuaDois</strong> também oferecerá suporte básico ao Usuário com relação aos Serviços, o que implica no esclarecimento de dúvidas com relação ao uso da Plataforma, bem como aos planos disponíveis, preços, eventuais problemas com relação a pagamentos e erros decorrentes da própria Plataforma. O fornecimento de suporte especializado deverá ser acordado entre a <strong>RuaDois</strong> e o Usuário em documento próprio, separadamente, o que implicará em custo adicional oportunamente definido a ser pago pelo Usuário solicitante.</p>
        <p>6.3. A <strong>RuaDois</strong> reserva-se ao direito de modificar, suspender ou descontinuar temporariamente as funcionalidades disponibilizadas na Plataforma para realizar a manutenção, atualização e ajustes de configuração da Plataforma. A <strong>RuaDois</strong> não será responsável, sob quaisquer circunstâncias, por eventuais perdas e danos, incluindo lucros cessantes, relacionados à suspensão do acesso à Plataforma.</p>
        <p>6.4. A <strong>RuaDois</strong> serviços de terceiros para manter a Plataforma funcionando, podendo eventualmente ocorrer falhas em tais serviços. A <strong>RuaDois</strong> não será responsável por quaisquer perdas e danos decorrentes de falha dos serviços destes terceiros.</p>
        <p>6.5. A <strong>RuaDois</strong> não se responsabiliza pelas falhas de acesso da Plataforma decorrentes de circunstâncias alheias à vontade e controle da <strong>RuaDois</strong>, inclusive, mas sem limitação, a falhas na internet em geral, quedas de energia, mau funcionamento eletrônico e/ou físico de qualquer rede de telecomunicações, interrupções ou suspensões de conexão e falhas nos software e/ou hardware utilizados pelos Usuários, bem como paralisações programadas para manutenção, atualização e ajustes de configuração da Plataforma.</p>
        <p>6.6. A Plataforma pode conter links ou ligações a sites ou serviços de terceiros que não pertencem e nem são controlados pela <strong>RuaDois</strong>, de forma que a <strong>RuaDois</strong> não assume qualquer responsabilidade pelo conteúdo, precisão, políticas, práticas, opiniões expressas em qualquer desses sites de terceiros ou por qualquer terceiro que o Usuário interaja através da Plataforma. A <strong>RuaDois</strong> sugere que o Usuário leia os termos de uso e as políticas de privacidade de cada site de terceiros ou serviço que o Usuário visitar ou utilizar.</p>
        <p>6.7. Nenhuma funcionalidade da Plataforma deve ser entendida como aconselhamento ou consultoria a respeito das atividades comerciais desenvolvidas pelos Usuários. A <strong>RuaDois</strong> não garante aos Usuários qualquer resultado comercial e não será responsável pelo desenvolvimento de seus negócios ou consequências decorrentes.</p>

        <h3>7. Responsabilidades do Usuário</h3>

        <p>7.1. São responsabilidades do Usuário:</p>
        <ul style="list-style-type: circle;">
          <li>(i) utilizar a Plataforma de forma adequada e diligente, em conformidade com os presentes Termos de Uso, com as leis brasileiras vigentes, portarias, normas, medidas provisórias, moral, bons costumes e ordem pública;</li>
          <li>(ii) manter o ambiente de seus dispositivos de acesso à Plataforma seguro, valendo-se de ferramentas específicas para tanto, tais como antivírus, firewall, entre outras, de modo a contribuir para a prevenção de riscos eletrônicos;</li>
          <li>(iii) utilizar sistemas operacionais atualizados e eficientes para a plena utilização da Plataforma;</li>
          <li>(iv) equipar-se e responsabilizar-se pelos dispositivos de hardware necessários para o acesso à Plataforma, bem como pelo acesso desses à Internet;/li>
          <li>(v) não explorar maliciosamente a segurança da Plataforma para a prática de atos ilícitos, proibidos pela Lei e pelos presentes Termos de Uso, lesivos aos direitos e interesses de terceiros, ou que, de qualquer forma, possa danificar, inutilizar, sobrecarregar ou deteriorar a Plataforma, bem como os equipamentos de informática de outros Usuários ou de outros clientes (hardware e software), assim como os documentos, arquivos e todo conteúdo armazenado em seus dispositivos ou impedir a normal utilização ou gozo da Plataforma e dos Serviços;</li>
          <li>(vi) manter cópia de todo conteúdo e dos dados que julgue importante, tendo em vista que a Plataforma pode passar por períodos de manutenção ou mesmo ter alguma perda de dados;</li>
          <li>(vii) garantir a veracidade, qualidade, integridade e legalidade de seus dados e do meio através do qual adquiriu os seus dados;</li>
          <li>(viii) agir de forma a coibir o acesso não autorizado à utilização dos Serviços e notificar a <strong>RuaDois</strong> imediatamente de qualquer acesso não autorizado ou uso;</li>
          <li>(ix) não vender, revender, alugar ou arrendar o Serviço;</li>
          <li>(x) não disponibilizar, alugar, divulgar ou ceder, ainda que gratuitamente, sua licença, seu login ou sua senha de acesso;</li>
          <li>(xi) inserir e armazenar os arquivos no Plataforma, sempre verificando previamente se o arquivo solicitado ou enviado contém vírus ou não;</li>
          <li>(xii) verificar se o conteúdo do arquivo inserido na Plataforma corresponde ao que nele é anunciado.</li>
        </ul>
        <p>7.2. O Usuário declara e garante que todos os conteúdos inseridos por ele na Plataforma:</p>
        <ul style="list-style-type: circle;">
          <li>(i) não violam a legislação brasileira, os dispositivos destes Termos de Uso e demais normas aplicáveis;</li>
          <li>(ii) não infringem, nem infringirão, qualquer obrigação ou direto de quaisquer terceiros, seja pessoa natural ou entidade, incluindo, mas não se limitando à, direitos de propriedade intelectual, direitos autorais, direitos de imagem, privacidade, direitos do consumidor, entre outros, e que;</li>
          <li>(iii) não são ilegais, como, por exemplo, racistas, ameaçador, obsceno, pornográficos ou violentos e nem incitam à violência, tampouco atingem a honra de terceiros, não são injuriosos, caluniosos e/ou difamantes, entre outros.</li>
        </ul>
        <p>7.3. Qualquer dano causado pelo Usuário à <strong>RuaDois</strong> ou a terceiros em virtude do não cumprimento das obrigações aqui dispostas ou da não veracidade das garantias aqui declaradas será reparado exclusivamente pelo Usuário causador do dano, não havendo que se falar em subsidiariedade da obrigação, tampouco em solidariedade da <strong>RuaDois</strong>.</p>

        <h3>8. Propriedade intelectual</h3>

        <p>8.1. Sujeito a estes Termos de Uso, a <strong>RuaDois</strong> concede ao Usuário uma licença limitada, temporária, não exclusiva e não transferível para usar a Plataforma somente naquilo que seja estritamente necessário para o cumprimento das obrigações e fruição dos direitos dispostos nestes Termos de Uso.</p>
        <p>8.2. Todos os direitos relativos e à Plataforma, bem como as suas funcionalidades, são de titularidade da <strong>RuaDois</strong>, inclusive no que diz respeito a todos os direitos de propriedade intelectual relacionados aos seus textos, imagens, gráficos, marcas, layouts, códigos, bases de dados e demais conteúdos produzidos direta ou indiretamente pela <strong>RuaDois</strong> (“Direitos de Propriedade Intelectual”). É expressamente proibida a utilização indevida de quaisquer conteúdo ou marca apresentados na Plataforma.</p>
        <p>8.3. Os Direitos de Propriedade Intelectual são protegidos pelas leis de direitos autorais e de propriedade industrial. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, ceder, executar, fazer o upload, exibir, licenciar, vender ou explorar os Direitos de Propriedade Intelectual para qualquer finalidade. Nenhuma cópia, distribuição, engenharia reversa, exibição da Plataforma, do site e/ou dos Direitos de Propriedade Intelectual devem ser entendidos como restrição ou renúncia dos direitos de propriedade intelectual da <strong>RuaDois</strong>.</p>
        <p>8.4. Qualquer utilização dos Direitos de Propriedade Intelectual só poderá ser feita mediante prévia e expressa autorização da <strong>RuaDois</strong>. O Usuário assume toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida e não autorizada dos Direitos de Propriedade Intelectual.</p>
        <p>8.5. A <strong>RuaDois</strong> poderá utilizar, sem prévia autorização do Usuário, a marca, logomarca e nome empresarial do Usuário, desde que para fins de divulgação e promoção da parceira.</p>
        <p>8.6. Qualquer sugestão, solicitação de melhorias, recomendações ou outras ideias fornecidas pelo Usuário à <strong>RuaDois</strong>, relacionadas com o funcionamento da Plataforma, não conferirá ao Usuário qualquer direito de titularidade sobre as funcionalidades eventualmente implementadas pela <strong>RuaDois</strong>, de forma que o Usuário não terá qualquer direito de retenção, uso ou indenização.</p>

        <h3>9. Confidencialidade e Proteção de Dados</h3>

        <p>9.1. Em razão da relação jurídica existente entre as partes, tanto a <strong>RuaDois</strong> quanto o Usuário se obrigam a manter o mais completo e absoluto sigilo sobre quaisquer dados, materiais, informações, documentos, especificações técnicas, inovações ou aperfeiçoamento de quaisquer produtos que lhe venham a ser confiados, sob pena de responder por e reparar qualquer dano, prejuízo, lesão ou perda de caráter patrimonial, moral, técnico, ou de qualquer outra natureza, que possa advir para a parte inocente da quebra, perda ou violação dessa obrigação de tratar com sigilo e ou confidencialidade os dados, materiais, informações, documentos, especificações e inovações.</p>
        <p>9.2. Proteção de dados: O uso e tratamento de dados e informações fornecidas tanto pelos Usuários como aquelas decorrentes do completo e adequado uso das ferramentas, capazes de identificar pessoas físicas, bem como o conteúdo ou as comunicações privadas ocorridas durante a prestação dos Serviços (<strong>Dados Pessoais</strong>) se darão de acordo com a legislação brasileira vigente aplicável e com o disposto nesta Cláusula. A <strong>RuaDois</strong> se compromete a manter as defesas técnicas apropriadas para proteger a confidencialidade e a integridade dos Dados Pessoais de seus Usuários e daqueles que porventura venham a utilizar a Plataforma no decorrer dos processos viabilizados pelos Serviços presentes na Cláusula 1 deste Termo de Uso. A <strong>RuaDois</strong> se compromete a não alterar ou divulgar os Dados Pessoais, exceto se exigido pela lei, ou com a anuência expressa de seus titulares. A <strong>RuaDois</strong> também se compromete a não acessar os Dados Pessoais hospedados em sua Plataforma, exceto para prestar os Serviços, suporte ou durante a resolução de problemas de serviço ou técnicos, ou, ainda, em virtude de pedido feito pelo próprio Usuário em nossos canais de suporte ao cliente.</p>
        <p>9.3. A <strong>RuaDois</strong> poderá utilizar os Dados Pessoais, cruzá-los e/ou enriquecê-los com outros Dados Pessoais que já estejam em sua posse, ou que venha a adquirir e incluir em seus sistemas, para a criação de perfis demográficos e de consumo de pessoas físicas e jurídicas; para levantamento de informações estatísticas e de mercado; como substrato para a criação ou para a análise do sucesso de anúncios, online ou off-line, próprios ou de seus parceiros comerciais; para a melhoria dos produtos e algoritmos da <strong>RuaDois</strong> ou de seus parceiros comerciais; assim como para a preparação de relatórios, métricas e outras soluções e softwares de inteligência de negócios para uso interno. Ademais, os Dados Pessoais podem ser utilizados para a criação de novos serviços, produtos e funcionalidades. O eventual uso dos Dados Pessoais para finalidades que não cumpram com essa prerrogativa será feito mediante notificação prévia ao Usuário (<strong>Usos Permitidos</strong>), sempre que possível e razoável. Em nenhuma hipótese os Dados Pessoais poderão ser divulgados à terceiros de modo que se permita a identificação individual da informação.</p>

        <h3>10. Alteração destes Termos de Uso</h3>

        <p>10.1 A <strong>RuaDois</strong> está sempre fazendo atualizações para melhorar as funcionalidades da Plataforma. Por esse motivo, estes Termos de Uso podem ser alterados, a qualquer tempo e a exclusivo critério da <strong>RuaDois</strong>, a fim de refletir os ajustes realizados na Plataforma. Sempre que ocorrer qualquer modificação nesses Termos de Uso, a <strong>RuaDois</strong> enviará um e-mail ao Usuário indicando a data em que a referida alteração passará a ser vigente e solicitará ao Usuário o aceite das novas disposições, por meio de checkbox que será disponibilizado ao Usuário ao acessar a Plataforma. Caso o Usuário não concorde com as novas disposições dos Termos de Uso, o Usuário poderá, a seu exclusivo critério, rejeitá-lo, o que resulta na impossibilidade de acesso à Plataforma e, por conseguinte, do uso de suas funcionalidades. Caso o Usuário continue a fazer uso da Plataforma, mesmo após a alteração dos Termos de Uso, isso significa que o usuário concorda, desde já, com todas as modificações.</p>

        <h3>11. Outras Disposições</h3>

        <p>11.1. Estes Termos de Uso não criam qualquer outra modalidade de vínculo entre os Usuários e a <strong>RuaDois</strong>, inclusive, sem limitação, sociedade, joint-venture, mandato, representação, parceria, consórcio, associação, formação de grupo econômico, vínculo empregatício ou similar. A <strong>RuaDois</strong> permanecerá uma entidade independente e autônoma.</p>
        <p>11.2. A <strong>RuaDois</strong> poderá ceder os direitos e obrigações referentes a estes Termos de Uso a empresas de seu mesmo grupo econômico ou societário, mediante simples comunicação por escrito ao Usuário.</p>
        <p>11.3. A omissão ou tolerância da <strong>RuaDois</strong> em exigir o estrito cumprimento dos termos e condições aqui definidos não constituirá, em nenhuma hipótese, em novação ou renúncia, nem impedirá que a <strong>RuaDois</strong> cobre esses direitos a qualquer tempo.</p>
        <p>11.4. O Usuário poderá entrar em contato direto com a <strong>RuaDois</strong> por meio do ícone 'Dúvidas’ disponível no site da empresa, ou por meio dos seguintes canais de comunicação:</p>
        <ul style="list-style-type: circle;">
          <li>e-mail: contato@ruadois.com.br</li>
        </ul>
        <p>11.4.1. O horário de atendimento é de segunda à sexta feira das 9h às 18h, nos dias úteis e das 8h às 14h aos sábados.</p>
        <p>11.5. Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. Quaisquer dúvidas e situações não previstas nestes Termos de Uso serão primeiramente resolvidas pela <strong>RuaDois</strong> e, caso persistam, deverão ser solucionadas no foro da cidade de São Paulo, Estado de São Paulo, com renúncia de qualquer outro, por mais privilegiado que seja.</p>
        <p>11.6. Qualquer dúvida ou solicitação relacionada a estes Termos de Uso deverá ser enviada à <strong>RuaDois</strong> por meio do e-mail contato@ruadois.com.br.</p>
        <p>11.7 Ao assinar estes Termos de Uso, o usuário declara que leu e concordou com o documento.</p>
      <div class="container__button">
        <button-component
          design-type="default"
          font-size="20px"
          type="button"
          :rounded="false"
          :padding-v="'35px'"
          :padding-h="'14px'"
          :to="`/`"
        >
          Ir para a página inicial
        </button-component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService'
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.terms {
  display: flex;
  justify-content: center;
  width: 100vw;

  .container {
    display: block;
    width: 40vw;
    text-align: justify;

    &__logo {
      display: block;
      margin: 40px auto;
      height: 50px;
    }

    h2 {
      margin-bottom: 60px;
      text-align: center;
    }

    h2,
    h3 {
      color: #2D5AA0;
    }

    p,
    li {
      color: $dark-text-color;
    }

    h2,
    h3,
    p,
    ul,
    ol {
      margin-top: 30px;
    }

    li {
      margin-bottom: 10px;
      line-height: 25px;
      font-size: 1.2rem;
    }

    &__button {
      display: block;
      margin-top: 40px;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      width: 265px;
    }
  }
}
</style>
