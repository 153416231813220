<template>
  <div class="terms">
    <div class="container">
      <img
            class="container__logo"
            :src="
              'https://r2service-production.s3-sa-east-1.amazonaws.com/assets/images/logo-ruadois.png'
            "
      >
        <h2>Políticas de Privacidade</h2>

        <p>Esta plataforma é mantida e operada por RUA DOIS SERVIÇOS DE TECNOLOGIA DA INFORMAÇÃO LTDA (“RuaDois”).</p>
        <p>A política de privacidade e os termos de uso se aplicam a todos os usuários que acessam nosso website, aplicativo, dashboard, bem como qualquer outra propriedade eletrônica (“Plataforma”) disponibilizada pela RuaDois.</p>
        <p>Atuamos no segmento de Locação do Mercado Imobiliário, mais especificamente como uma plataforma de Serviços em Tecnologia da Informação (SaaS) e a privacidade dos nossos usuários e dos visitantes do nosso site é muito importante para nós, por isso, estamos empenhados e comprometidos em protegê-la. Coletamos e utilizamos alguns dados pessoais pertencentes aos usuários que utilizam a Plataforma, com a finalidade de oferecer um serviço de excelência para imobiliárias e seus clientes. Agimos, portanto, na qualidade de controlador desses dados, tomando decisões de tratamento, nos termos da presente Política de Privacidade.</p>
        <p>No caso do Usuário Imobiliária, a RuaDois é tanto controlador dos dados pessoais, quando toma decisões de tratamento, como também desempenha papel de operador dos dados pessoais coletados pela Imobiliária no que se refere à execução dos serviços inerentes ao processo de locação de imóveis – quais sejam: dados do Proprietário, dados do Locatário, dados do Indicador, quando estes não tenham vindo por intermédio da Plataforma. A Imobiliária é responsável por avisar ao Proprietário que a RuaDois é um controlador de dados pessoais.</p>
        <p>Para as finalidades dessa Política de Privacidade, será considerado usuário a Imobiliária, os Locatários, os Proprietários, os Hosts e seus respectivos representantes legais, empregados, prepostos e/ou agentes (“Usuário”).</p>

        <h3>Introdução</h3>

        <p>Disponibilizamos esta política de privacidade, que contém informações importantes sobre:</p>
        <ol style="list-style-type: lower-roman;">
          <li>Que dados são tratados pela RuaDois;</li>
          <li>Para quais finalidades usamos os dados pessoais dos Usuários;</li>
          <li>Por quanto tempo os dados são tratados pela RuaDois;</li>
          <li>Quais os direitos dos Usuários em relação aos dados pessoais tratados pela RuaDois;</li>
          <li>Como entrar em contato com a RuaDois para exercer os seus direitos como titular dos dados pessoais.</li>
        </ol>

        <p>Esta política explica, portanto, o que faremos com as informações pessoais dos nossos Usuários e visa esclarecer a todos os(as) interessados(as) sobre os tipos de dados que coletamos, os motivos da coleta e a forma como os Usuários podem gerenciar ou excluir suas informações pessoais.</p>
        <p>Os presentes termos foram elaborados em conformidade com a Lei Geral de Proteção de Dados pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) e o Regulamento da EU (n.2016/6790).</p>

        <h3>1. Definições de Usuários</h3>

        <p>Para as finalidades dessa Política de Privacidade, os termos e expressões a seguir, quando escritos em letras maiúsculas, deverão ter os seguintes significados, conforme definido abaixo:</p>

        <ol style="list-style-type: lower-alpha;">
          <li>Usuário da Plataforma: indivíduo que acesse e/ou efetue cadastramento de perfil pessoal ou comercial na Plataforma, incluindo Imobiliárias, Proprietários, Locatários, Hosts e Indicadores;</li>
          <li>Imobiliária: pessoa jurídica que exerce atividade imobiliária e que utiliza os serviços de Plataforma e de agenciamento de visitas da RuaDois. Esta definição também inclui, além da própria imobiliária, os seus representantes legais, empregados, prepostos e agentes;</li>
          <li>Proprietário: indivíduo ou empresa que mantém relação comercial de administração de locação com a Imobiliária;</li>
          <li>Locatário: indivíduo que mantém relação comercial e jurídica com a Imobiliária e, por meio desta, utiliza-se dos serviços prestados pela RuaDois para locação de imóveis;</li>
          <li>Host: empresa ou prestador autônomo que adere à Plataforma e contrata os serviços de agenciamento de visitas prestados pela RuaDois para intermediação dos serviços a serem prestados às Imobiliárias;</li>
          <li>Indicadores: indivíduo ou empresa que utiliza os serviços de indicação de imóveis da RuaDois.</li>
        </ol>

        <h3>2. Dados que coletamos e motivos da coleta</h3>

        <p>Nosso site coleta e utiliza alguns dados pessoais dos Usuários da seguinte forma:</p>
        <h3>2.1. Dados pessoais fornecidos pelo Usuário ou obtidos pela RuaDois</h3>
        <p>Coletamos os seguintes dados pessoais que os usuários nos fornecem ao utilizar nosso site ou se cadastrar em nossa Plataforma:</p>
        <ol style="list-style-type: circle;">
          <li>Nome completo;</li>
          <li>Endereço de e-mail;</li>
          <li>Números de telefone;</li>
          <li>Mensagens de contato;</li>
          <li>Assinatura;</li>
          <li>Nacionalidade;</li>
          <li>Estado Civil;</li>
          <li>Filiação;</li>
          <li>Dados e imagens da carteira de identidade (RG);</li>
          <li>Dados e imagens do cadastro de pessoas físicas (CPF/ME);</li>
          <li>Dados e imagens da Carteira Nacional de Habilitação (CNH);</li>
          <li>CNPJ;</li>
          <li>Certidão de Casamento;</li>
          <li>Endereço completo e comprovantes de endereço;</li>
          <li>Organização ou empresa a qual pertence ou esteja relacionado e informações relacionadas;</li>
          <li>Posição, título ou cargo;</li>
          <li>Informações de contrato;</li>
          <li>Informações sobre histórico pessoal, profissional e, conforme permitido pela legislação, antecedentes criminais;</li>
          <li>Informações bancárias e financeiras, como Imposto de Renda, comprovante de salário e extrato bancário, incluindo dados de cartão de crédito;</li>
          <li>Informações sobre crédito;</li>
          <li>Comunicação, verbal ou escrita, mantida entre os Usuários e a RuaDois;</li>
          <li>Informações técnicas, como seu endereço de IP, dados de geolocalização e localização geográfica, palavras-chave e termos buscados, fonte de referência, tipo e versão de navegador, páginas visitadas, tempo de duração de cada visita e histórico de visitas, dados contextuais baseados na experiência de navegação, como datas e horários de acesso mais frequentes, contagem de cliques em links e botões, número de downloads de materiais, aberturas de newsletters;</li>
          <li>Dados de transações e pagamentos feitas por meio do site;</li>
          <li>Informações sobre cookies, quando os Usuários acessam o site, a Plataforma ou outras propriedades eletrônicas da RuaDois.</li>
        </ol>
        <p>Em se tratando de circunstâncias específicas, a exemplo da averiguação do relacionamento dos Usuários dentro da Plataforma, do cumprimento das boas práticas, dos princípios da boa vizinhança e da regulamentação anticorrupção, a RuaDois também pode obter e tratar dados pessoais advindos de outras fontes, como sites de acesso público e por meio de provedores de verificação de antecedentes, conforme as leis e demais regulamentos que versam sobre a proteção de dados.</p>

        <h3>2.2. Finalidade e duração do tratamento</h3>

        <p>Os dados coletados dos Usuários são tratados com extrema transparência e responsabilidade pela RuaDois e seguem finalidades específicas, cada uma delas pautadas em sua base legal devida, conforme descrito nas cláusulas abaixo.</p>
        <p>A fim de esclarecer quais bases legais a RuaDois utiliza para legitimar o uso dos dados pessoais dos Usuários, faz-se necessário entender os momentos em que esses dados são obtidos e a sua relevância para a implementação de um processo digital de locação de imóveis. São eles:</p>
        <ol style="list-style-type: circle;">
          <li>Quando o usuário utiliza o formulário de cadastro;</li>
          <li>Quando o usuário entra em contato por meio de aplicativos de mensagens, chats, ligações telefônicas, videochamadas ou outra via;</li>
          <li>Quando o usuário agenda uma reunião, consulta ou demonstração de nossos produtos e serviços;</li>
          <li>Quando o usuário faz uma compra online de produtos da Plataforma;</li>
          <li>Quando o usuário se cadastra para uma utilização gratuita em forma de teste (Modalidade Freemium);</li>
          <li>Quando o usuário fornece o link do seu xml para integração com a Plataforma;</li>
          <li>Quando a Imobiliária cadastra informações do Proprietário, Locatário ou Indicador, em nome destes, na Plataforma;</li>
          <li>Quando o Indicador, de forma independente e orgânica, realiza indicações de imóveis pela Plataforma;</li>
          <li>Quando o Proprietário, de forma independente e orgânica, cadastra o seu imóvel na Plataforma;</li>
          <li>Quando o Locatário, de forma independente e orgânica, realiza agendamentos de visitas pela plataforma;</li>
          <li>Quando o Locatário envia propostas de locação por meio da plataforma;</li>
          <li>Quando o Locatário utiliza o formulário de garantias para fornecer suas informações financeiras;</li>
          <li>Quando a Imobiliária utiliza o formulário de geração de contratos;</li>
          <li>Quando o usuário se cadastra para receber boletins, descontos e notícias sobre a RuaDois.</li>
        </ol>
        <p>Os dados fornecidos por nossos usuários em todos esses contextos e/ou momentos do processo de locação de imóveis são coletados com as seguintes finalidades:</p>
        <ol style="list-style-type: circle;">
          <li>Para viabilizar a sua navegação na Plataforma, permitindo à RuaDois identificar problemas, implementar melhorias no produto e na Plataforma, personalizar e configurar a Plataforma conforme as necessidades de cada Usuário e garantir o monitoramento da segurança;</li>
          <li>Para assegurar a prestação dos serviços oferecidos pela RuaDois e possibilitar a implementação de um processo de locação de imóveis digital;</li>
          <li>Para atender a solicitações e contatos, receber reclamações, apurar denúncias ou responder a requisições dos Usuários e outros agentes envolvidos na operação;</li>
          <li>Para estabelecer o contato dos Usuários com os nossos canais de atendimento, a fim de garantir o cumprimento de toda e qualquer obrigação que possa surgir da relação entre RuaDois e Usuários;</li>
          <li>Para realizar a gestão de relacionamento com os Clientes, inclusive através de comunicação pelos meios disponíveis, marketing direto, publicidade e ações promocionais;</li>
          <li>Para fins de marketing e publicidade dos serviços da RuaDois, incluindo publicidade direcionada conforme perfil de comportamento, consumo e/ou localização;</li>
          <li>Para enviar materiais informativos e conteúdos educativos sobre a RuaDois e/ou qualquer outro assunto relacionado ao mercado imobiliário;</li>
          <li>Para que os nossos colaboradores possam entrar em contato via email, chat, telefone e mensagens de texto para presta auxílio, realizar pesquisas de satisfação ou de qualquer outra natureza, apresentar novos produtos e serviços e/ou realizar algum comunicado sobre atualizações na Plataforma;</li>
          <li>Para verificação da identidade do Usuário;</li>
          <li>Para o exercício legal de direitos da RuaDois, inclusive em contratos e processos judiciais, administrativos ou arbitrais, bem como para o cumprimento das obrigações legais e fiscais da RuaDois;</li>
          <li>Para preparar respostas a autoridades no âmbito de processos judiciais ou administrativos, em cumprimento de deveres legais, regulatórios ou quaisquer outros licitamente exigíveis à RuaDois, ou em defesa de seus direitos e interesses legítimos;</li>
          <li>Para a realização de atividades de prevenção contra fraude e atividades ilícitas, incluindo medidas para proteção da RuaDois, dos Usuários e/ou de terceiros;</li>
          <li>Para enviar faturas e lembretes de pagamento;</li>
          <li>Para enviar lembretes de notificações de visitas, propostas, aprovação de crédito e assinatura de contratos;</li>
          <li>Para proteção ao crédito;</li>
          <li>Para todo e qualquer fim que o Usuário nos autorizar expressamente no momento da coleta dos dados.</li>
        </ol>
        <p>A RuaDois se compromete a utilizar os dados pessoais apenas para as finalidades acima descritas, respeitando as normas de proteção dos dados pessoais, e armazenar esses dados pelo período de tempo que for necessário para atingir as finalidades elencadas neste documento, considerando os direitos de seus titulares, os direitos da RuaDois como controlador e as disposições legais ou regulatórias aplicáveis.</p>
        <p>A RuaDois também se compromete a descartar os dados sempre que possível, dentro da razoabilidade do exigido e disposto em lei, e sempre que solicitado pelos titulares. Em caso de dúvidas sobre a forma como a RuaDois está mantendo e descartando os dados pessoais dos Usuários, é possível contatar o nosso dpo pelo canal de atendimento aos titulares de dados no seguinte endereço de email: jader.marques@ruadois.com.br</p>

        <h3>3. Compartilhamento de Dados com Terceiros</h3>

        <p>Os dados coletados pela RuaDois podem ser compartilhados com terceiros, desde que para cumprimento das finalidades presentes nesta Política de Privacidade e de acordo com as hipóteses apresentadas a seguir:</p>
        <ol style="list-style-type: circle;">
          <li>Podemos compartilhar os seus dados com empresas parceiras da RuaDois ou prestadores de serviços contratados, realizando o tratamento de dados pessoais em nome e no interesse da RuaDois, com fins a garantir a boa prestação dos serviços e a completa digitalização do processo de locação de imóveis. São exemplos de empresas parceiras ou contratadas pela RuaDois: softwares de gestão de pagamentos, análise de crédito e assinatura digital; aplicativos de automação, e auditorias;</li>
          <li>Autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem judicial.</li>
        </ol>
        <p>Além das situações aqui informadas, é possível que compartilhemos dados com terceiros para cumprir alguma determinação legal, técnica ou regulatória. No caso de Informações a serem compartilhadas em cumprimento de solicitação de autoridade pública, a mesma sempre será efetuada mediante ordem judicial, conforme definido em Lei.</p>
        <p>Em qualquer caso, o compartilhamento de dados pessoais observará todo o conjunto de normas e regras aplicáveis, buscando sempre garantir a segurança dos dados dos Usuários, observados os padrões técnicos e as boas práticas de segurança da informação.</p>
        <p>Ao continuar navegando em nosso site ou ao utilizar a nossa Plataforma, você concorda com o compartilhamento dos seus dados, nas hipóteses e especificações aqui presentes.</p>
        <p>Todos os seus dados, informações e conteúdos podem ser considerados ativos no caso das negociações em que fizermos parte. Portanto, reservamo-nos o direito de incluir os seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra. Por meio desta Política você concorda e está ciente desta possibilidade.</p>

        <h3>4. Direitos do Titular dos Dados</h3>

        <p>O titular dos dados possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados Pessoais, que podem ser exercidos, a qualquer momento, mediante solicitação:</p>
        <ol style="list-style-type: circle;">
          <li>Confirmação da existência de tratamento;</li>
          <li>Acesso aos dados;</li>
          <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
          <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;</li>
          <li>Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
          <li>Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;</li>
          <li>Informação das entidades públicas e privadas com as quais a RuaDois tenha realizado o uso compartilhado de seus dados;</li>
          <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
          <li>Revogação do consentimento, quando o tratamento tenha sido realizado com base no consentimento.</li>
        </ol>
        <p>É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento em bases legais distintas do consentimento, a menos que os dados sejam desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.</p>

        <h3>4.1. Como o titular pode exercer seus direitos</h3>

        <p>Os titulares de dados pessoais tratados pela Ruadois poderão exercer seus direitos por meio do nosso canal de atendimento e comunicação específico para casos envolvendo titulares de dados pessoais. Sendo assim, os Usuários poderão formalizar qualquer solicitação, reclamação ou pedido de informações, por meio do endereço de email do nosso encarregado de Proteção de Dados Pessoais: jader.marques@ruadois.com.br ou pelo seguinte endereço aos cuidados do Encarregado de Dados da RuaDois (DPO):</p>
        <p>Alameda Vicente Pinzon, 54 - Vila Olímpia, São Paulo - SP, 04547-130</p>
        <p>Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.</p>

        <h3>5. Medidas de segurança no tratamento de dados pessoais</h3>

        <p>Empregamos medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.</p>
        <p>As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, as normas de segurança internacionais e os padrões empregados atualmente por empresas semelhantes à nossa.</p>
        <p>Ainda que façamos tudo que estiver ao nosso alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro, como em caso de ataques de hackers ou crackers ou, ainda, em casos de responsabilidade exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a um terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.</p>
        <p>De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos Usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei.</p>

        <h3>6. Acesso à plataforma</h3>

        <p>O Usuário se declara ciente de que seu login e senha são de uso pessoal e intransferível e que deverá mantê-lo sob sigilo e em ambiente seguro. Ao acessar a nossa Plataforma o Usuário não deverá utilizar como próprio o login e senha alheios ou ceder a outrem, para que deles se utilizem.</p>

        <h3>7. Alterações nesta política</h3>

        <p>A presente Política de Privacidade poderá ser atualizada periodicamente. Todas as alterações deverão ser consideradas como de aplicação e vigência imediata, exceto em caso de comunicação em contrário enviada pela RuaDois aos Usuários. A RuaDois tomará medidas razoáveis para comunicar os Usuários acerca das atualizações nos termos da Política de Privacidade.</p>
      <div class="container__button">
        <button-component
          design-type="default"
          font-size="20px"
          type="button"
          :rounded="false"
          :padding-v="'35px'"
          :padding-h="'14px'"
          :to="`/`"
        >
          Ir para a página inicial
        </button-component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.terms {
  display: flex;
  justify-content: center;
  width: 100vw;

  .container {
    display: block;
    width: 40vw;
    text-align: justify;

    &__logo {
      display: block;
      margin: 40px auto;
      height: 50px;
    }

    h2 {
      margin-bottom: 60px;
      text-align: center;
    }

    h2,
    h3 {
      color: #2D5AA0;
    }

    p,
    li {
      color: $dark-text-color;
    }

    h2,
    h3,
    p,
    ul,
    ol {
      margin-top: 30px;
    }

    li {
      margin-bottom: 10px;
      line-height: 25px;
      font-size: 1.2rem;
    }

    ul,
    ol {
      margin-left: 20px;
    }

    &__button {
      display: block;
      margin-top: 40px;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      width: 265px;
    }
  }
}
</style>
